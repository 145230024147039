<template>
  <div>
    <van-nav-bar title="选择模板" fixed left-arrow @click-left="$router.back()">
    </van-nav-bar>
    <van-tabs v-model="active" class="tabs-box" sticky offset-top="58px">
      <van-tab title="从JSA中选择">
        <jsaList
          ref="jsa"
          class="jsa-box"
          is-select
          @click="data => jsaClick(data, 0)"
        ></jsaList>
      </van-tab>
      <van-tab title="从模板库选择">
        <workBeforeSafetyAnalyze
          ref="workJsa"
          class="jsa-box"
          is-source
          is-select
          @click="data => jsaClick(data, 1)"
        />
      </van-tab>
    </van-tabs>
    <bottom-fixed-box>
      <van-button class="btn-default" @click="cancel">取消</van-button>
      <van-button class="btn-primary" @click="confirm">确认</van-button>
    </bottom-fixed-box>
  </div>
</template>

<script>
import workBeforeSafetyAnalyze from "@/views/workticket/workBeforeSafetyAnalyze/workBeforeSafetyAnalyze";
import jsaList from "@/views/workticket/workBeforeSafetyAnalyze/jsaList";
import BottomFixedBox from "@/components/general/BottomFixedBox";

export default {
  components: {
    workBeforeSafetyAnalyze,
    jsaList,
    BottomFixedBox
  },
  data() {
    return {
      active: 0,
      selected: ""
    };
  },
  computed: {},
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  methods: {
    jsaClick(val, type) {
      const obj = {
        0: "workJsa",
        1: "jsa"
      };
      this.selected = val;
      const ref = obj[type];
      this.$refs[ref] && this.$refs[ref].clearRadioChoose();
    },
    cancel() {
      this.$router.back();
    },
    confirm() {
      if (this.selected) {
        window.sessionStorage.setItem("temp", JSON.stringify(this.selected));
      }
      this.cancel();
    }
  }
};
</script>

<style lang="scss" scoped>
.tabs-box {
  margin: 58px 0 0;
  .jsa-box {
    padding: 0;
  }
}
</style>
