<template>
  <div class="work-query">
    <div class="search-slt-box">
      <div
        v-for="(item, idx) in configs.filter(x =>
          x.queryType.includes(queryType)
        )"
        :key="idx"
        class="work-query__item"
        @click="onSearchClick(item, idx)"
      >
        <span class="work-query__item-title">{{ item.title }}</span>
        <van-icon
          name="zhankai"
          class-prefix="iconfont"
          class="work-query__item-icon"
        />
      </div>
    </div>
    <cascade-department
      v-model="queryInfo.deptCode"
      :visible="departmentVisible"
      @close="departmentVisible = false"
      @confirm="refreshQuery"
    />
    <work-area-picker
      ref="work-area-picker"
      v-model="queryInfo.poiId"
      @change="onWorkAreaChange"
    ></work-area-picker>
    <select-person
      title="分析人(单选)"
      :visible="showAnalystPicker"
      :show-department-name="false"
      :show-phone-number="true"
      position="bottom"
      :multiple="false"
      :is-department-auth="false"
      :choose-value="analystSelected"
      @close="showAnalystPicker = false"
      @confirm="(ids, list) => onPersonConfirm(ids, list)"
    />
    <select-popup
      :choose-value="popupValue"
      :data="popupData"
      :title="popupTitle"
      :visible="popupVisible"
      :popup-style="{
        height: '80vh'
      }"
      @close="onPopupClose"
      @confirm="onPopupConfirm"
    />
  </div>
</template>

<script>
import SelectPopup from "@/components/SelectPopup";
import WorkAreaPicker from "@/components/picker/WorkAreaPicker";
import { getSelectWorkTypes } from "@/api/psm";
import { getJsaStatus } from "@/api/workticket";

export default {
  components: { WorkAreaPicker, SelectPopup },
  props: {
    query: Object,
    queryType: {
      type: String,
      default: "job",
      validate(value) {
        // job 是作业许可，gas 是气体检测
        return ["job", "gas"].indexOf(value) !== -1;
      }
    }
  },
  data() {
    return {
      queryInfo: {
        workTypeCode: "",
        status: "",
        poiId: "", //作业区域
        deptCode: "",
        groupLeader: "", // 分析人
        //  检测结果
        result: ""
      },
      selectWorkType: [],
      selectJsaStatus: [],
      analyzeSltData: [],
      selectTestResult: [
        { label: "合格", value: "1" },
        { label: "不合格", value: "0" }
      ],
      configs: [
        {
          title: "所属部门",
          type: "deptCode",
          queryType: ["job", "gas"]
        },
        {
          title: "作业区域",
          type: "poiId",
          queryType: ["job", "gas"]
        },
        {
          title: "作业类别",
          type: "workTypeCode",
          types: "selectWorkType",
          queryType: ["job", "gas"]
        },
        {
          title: "状态",
          type: "status",
          types: "selectJsaStatus",
          queryType: ["job"]
        },
        {
          title: "分析人",
          type: "groupLeader",
          types: "analyzeSltData",
          queryType: ["job"]
        },
        {
          title: "检测结果",
          type: "result",
          types: "selectTestResult",
          queryType: ["gas"]
        }
      ],
      departmentVisible: false,
      popupVisible: false,
      popupTitle: "",
      popupData: [],
      selectValue: "",
      popupValue: "",
      popupType: "",
      showAnalystPicker: false,
      analystSelected: []
    };
  },
  created() {
    this.initTypes();
  },
  methods: {
    async initTypes() {
      try {
        const ret = await Promise.all([getSelectWorkTypes(), getJsaStatus()]);
        this.selectWorkType =
          ret[0] && ret[0].map(x => ({ label: x.name, value: x.id }));
        this.selectJsaStatus = ret[1];
      } catch (e) {
        console.log(e);
      }
    },
    onWorkAreaChange(a, b) {
      this.refreshQuery();
    },
    onPersonConfirm(ids, list) {
      this.analystSelected = list;
      this.queryInfo.groupLeader = list[0] ? list[0].id : "";
      this.refreshQuery();
    },
    refreshQuery() {
      if (this.queryType === "gas") {
        delete this.queryInfo.status;
      }
      if (this.queryType === "job") {
        delete this.queryInfo.result;
      }
      this.$emit("refresh", this.queryInfo);
    },
    onSearchClick({ title, type, types }, idx) {
      if (type === "deptCode") {
        this.departmentVisible = true;
      } else if (type === "poiId") {
        this.$refs["work-area-picker"].show();
      } else if (type === "groupLeader") {
        this.showAnalystPicker = true;
      } else {
        this.popupValue = this.queryInfo[type] || "";
        this.popupType = type;
        this.popupData = this[types];
        this.popupTitle = title;
        this.popupVisible = true;
      }
    },
    onPopupReset() {
      this.queryInfo[this.popupType] = "";
      this.popupValue = "";
      this.refreshQuery();
    },
    onPopupClose() {
      this.popupVisible = false;
      this.popupData = [];
    },
    onPopupConfirm(row) {
      this.queryInfo[this.popupType] = row.value;
      this.refreshQuery();
    }
  }
};
</script>

<style lang="scss" scoped>
// .work-query {
//   display: flex;
//   justify-content: space-between;
//   padding: 12px 16px;
//   margin-top: -2px;
//   color: #3b4664;
//   font-size: 14px;
//   position: relative;
//   &:after {
//     position: absolute;
//     bottom: 0;
//     content: "";
//     height: 1px;
//     width: 100%;
//     left: 0;
//     border-bottom: 0.5px solid #c7c9d0;
//     transform: scaleY(0.5);
//   }
//   &__item {
//     flex: 1;
//     display: flex;
//     align-items: center;
//     &:nth-child(2),
//     &:nth-child(3) {
//       justify-content: center;
//     }
//     &:nth-child(4) {
//       justify-content: flex-end;
//     }
//     &-icon {
//       margin-left: 4px;
//       color: #aeb3c0;
//       font-size: 12px;
//     }
//   }
// }
.work-query {
  font-size: 14px;
  position: relative;
  .search-slt-box {
    display: flex;
    justify-items: center;
    align-items: center;
    white-space: nowrap;
    overflow-x: auto;
    padding: 12px 16px;
    color: #3b4664;
    .work-query__item {
      margin: 0 10px 0 0;
      .work-query__item-icon {
        margin-left: 4px;
        color: #aeb3c0;
        font-size: 12px;
      }
    }
  }
  &::after {
    position: absolute;
    bottom: 0;
    content: "";
    height: 1px;
    width: 100%;
    left: 0;
    border-bottom: 0.5px solid #c7c9d0;
    transform: scaleY(0.5);
  }
}
</style>
