<template>
  <div class="has-nav-bar view-workticket">
    <van-nav-bar
      v-if="!isSelect"
      title="作业前安全分析（JSA）"
      fixed
      left-arrow
      @click-left="$router.back()"
    >
      <template #right>
        <van-icon
          size="24"
          name="iconfont-xinzengfengxian"
          class-prefix="iconfont"
          class="iconfont-xinzengfengxian"
          @click="addWBSA()"
        />
      </template>
    </van-nav-bar>
    <div>
      <van-search
        v-model="query.jsaName"
        placeholder="请输入名称关键词"
        @search="getList(true)"
        @clear="getList(true)"
      />
      <date-time
        v-model="dateTime"
        :time-close="false"
        type="date"
        format="{y}-{m}-{d}"
        @input="getList(true)"
      />
      <WBSAWorkJsaQuery
        :query="query"
        @refresh="onQueryChange"
      ></WBSAWorkJsaQuery>
    </div>
    <LoadListView
      v-model="loadStatus"
      :list="list"
      :total="total"
      @load="getList"
    >
      <div
        v-for="(item, index) in list"
        :key="index"
        class="item-box block-space_8"
      >
        <div v-if="isSelect" class="radio-box">
          <van-radio-group v-model="radio" icon-size="18">
            <van-radio :name="item" @click="radioClick(item)"></van-radio>
          </van-radio-group>
        </div>
        <WBSA-WorkticketItem
          :id="item.id"
          :router-link="false"
          :title="item.jsaName"
          :number="item.jsaCode"
          :status="item.statusName"
          :types="item.workTypeName"
          :start-time="item.ctime"
          :end-time="item.planEnd"
          :text1="item.deptName"
          :text2="item.poiName"
          :text3="item.groupLeaderName"
        />
      </div>
    </LoadListView>
  </div>
</template>

<script>
import WBSAWorkticketItem from "@/views/workticket/components/WBSAWorkticketItem";
import LoadListView from "@/components/LoadListView";
import { getJsaByPage } from "@/api/workticket";
import { getTimeParseDate } from "@/utils";
import DateTime from "@/components/DatetimePicker";
import WBSAWorkJsaQuery from "@/views/workticket/components/WBSAWorkJsaQuery";

export default {
  props: {
    isSelect: {
      type: Boolean,
      default: false
    }
  },
  components: {
    WBSAWorkJsaQuery,
    DateTime,
    LoadListView,
    WBSAWorkticketItem
  },
  data() {
    return {
      dateTime: {
        start: "",
        end: ""
      },
      loadStatus: "loading",
      list: [],
      total: 0,
      query: {
        jsaName: "",
        workTypeCode: "",
        poiId: "",
        poiType: "",
        poiName: "",
        deptCode: "",
        status: "",
        orgCode: this.$store.state.login.userInfo.orgCode,
        beginTime: "",
        endTime: "",
        groupLeader: "",
        page: 1,
        size: 10
      },
      departmentLabel: "",
      areaLabel: "",
      typeLabel: "",
      statusLabel: "",
      departmentVisible: false,
      radio: ""
    };
  },
  computed: {
    workTypeOptions() {
      return this.$store.state.workticket.workTypeOptions;
    },
    workStatusOptions() {
      return this.$store.state.workticket.workStatusOptions;
    },
    userInfo() {
      return this.$store.state.login.userInfo;
    }
  },
  created: function() {},
  mounted: function() {
    this.getList(true);
    // this.$store.dispatch("getWorkTypeOptions");
    // this.$store.dispatch("getWorkStatusOptions");
  },
  methods: {
    onQueryChange(query) {
      this.query = { ...this.query, ...query };
      this.getList(true);
    },
    /**
     * 是否是刷新,重新加载第一页
     * @param refresh
     */
    getList(refresh = false) {
      if (refresh) {
        this.query.page = 1;
        this.list = [];
        this.loadStatus = "loading";
      }

      getJsaByPage({
        ...this.query,
        orgCode: this.userInfo.orgCode,
        beginTime: getTimeParseDate(
          this.dateTime.start && this.dateTime.start + " 00:00:00"
        ),
        endTime: getTimeParseDate(
          this.dateTime.end && this.dateTime.end + " 23:59:59"
        )
      })
        .then(({ total, list }) => {
          this.list = this.list.concat(
            list.map(i => {
              i.workTypeName = (i.workTypeName || []).toString();
              i._dataTag = "jsa";
              return i;
            })
          );
          this.total = total;
          this.loadStatus = "success";
          if (list && list.length > 0) {
            this.query.page++;
          }
        })
        .catch(error => {
          console.log(error);
          this.loadStatus = "error";
        });
    },
    addWBSA() {
      this.$router.push("/workbeforesafetyanalyze/add");
    },
    radioClick(data) {
      this.$emit("click", data);
    },
    clearRadioChoose() {
      this.radio = "";
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.item-box {
  display: flex;
  align-items: center;
  .radio-box {
    padding: 0 0 0 16px;
  }
}
</style>
